<template>
  <div>
    <div
      class="p-input-file"
      :class="{ preview: !!preview }"
      @drag.stop.prevent
      @dragstart.stop.prevent
      @dragenter.stop.prevent="onDragEnter"
      @dragover.stop.prevent="onDragEnter"
      @dragleave.stop.prevent="onDragLeave"
      @dragend.stop.prevent="onDragLeave"
      @drop.stop.prevent="onFileDrop"
    >
      <div
        v-if="preview"
        class="p-input-file__preview"
      >
        <span
          class="p-input-file__remove"
          aria-label="Supprimer l'image"
          @click="removePreview"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.407 15.053"
          >
            <path
              fill="#363636"
              d="M4.877 0a1.179 1.179 0 00-1.171 1.171v.5H.476a.5.5 0 00.052 1h10.694l-.324 11.19c0 .119-.066.183-.167.183H2.703c-.1 0-.164-.064-.167-.183l-.293-10.03a.5.5 0 10-1 .026l.293 10.03a1.194 1.194 0 001.171 1.16h8.028a1.194 1.194 0 001.171-1.16L12.23 2.67h.679a.5.5 0 100-1H9.727v-.5A1.179 1.179 0 008.56 0zm0 1H8.56a.157.157 0 01.167.167v.5H4.71v-.5A.157.157 0 014.877 1zM4.37 4.338a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512zm2.342 0a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512zm2.342 0a.5.5 0 00-.5.512v7.025a.5.5 0 101 0V4.85a.5.5 0 00-.507-.512z"
            />
          </svg>
        </span>
        <img :src="preview" />
        <div
          v-if="!basic"
          class="c-form__group"
        >
          <input
            :id="`alt-${name}`"
            v-model="alt"
            type="text"
            class="c-form__control"
            :name="`alt-${name}`"
            :placeholder="`Légende de la photo${
              altRequired ? '' : ' (facultatif)'
            }`"
            :required="altRequired"
            @input="onAltChange"
          />
        </div>
      </div>
      <label
        v-else
        class="p-input-file__waiting"
        :class="{ 'is-dragging': draggingOver }"
        :for="name"
      >
        <input
          :id="name"
          type="file"
          :name="name"
          class="p-input-file__input"
          :accept="accept"
          :required="required"
          @change="onFileChange"
        />
        <img
          src="~/assets/icons/add.svg"
          alt="Cliquer ou glisser-déposer une image"
        />
        <p>
          {{ title }}{{ !required ? ' (facultatif)' : '*' }}<br />
          <span v-if="instructions">{{ instructions }}</span>
        </p>
      </label>
    </div>
    <span
      v-if="error || apiError"
      class="c-form__feedback is-invalid"
    >
      {{ error }} {{ apiError }}
    </span>
  </div>
</template>

<script setup lang="ts">
import type { CustomMedia } from '~/types'

const props = withDefaults(
  defineProps<{
    image?: CustomMedia
    name?: string
    basic?: boolean
    required?: boolean
    altRequired?: boolean
    accept?: string
    mimetype?: string
    instructions?: string
    title?: string
    post?: boolean
    apiError?: string
  }>(),
  {
    image: () => ({}),
    name: 'filepreview',
    basic: false,
    required: false,
    altRequired: false,
    accept: '.jpg,.png,.gif',
    mimetype: 'image/jpeg,image/png,image/gif',
    instructions: 'JPEG, PNG 5Mo max. GIF 2Mo max.',
    title: 'Image',
    post: false,
    apiError: undefined,
  },
)
const emit = defineEmits<{
  change: [image: { preview: string; alt: string }]
}>()

const draggingOver = ref(false)
const preview = ref(props.image?.preview ?? '')
const alt = ref(props.image?.alt ?? '')
const error = ref('')

const onDragEnter = () => {
  draggingOver.value = true
}

const onDragLeave = () => {
  draggingOver.value = false
}

const onFileDrop = (e: DragEvent) => {
  onDragLeave()
  onFileChange(e)
}

const getFiles = (event: Event) => {
  if (event.target instanceof HTMLInputElement) {
    return event.target.files
  }
  if (event instanceof DragEvent) {
    return event.dataTransfer?.files
  }
}
const onFileChange = (event: Event) => {
  const files = getFiles(event)

  if (!files?.length) return

  error.value = ''

  const file = files[0]

  if (props.post) {
    if (!props.mimetype.includes(file.type)) {
      error.value = "Ce type de fichier n'est pas autorisé."
      return
    }

    const maxSize = file.type.includes('image/gif') ? 2 : 5

    if (file.size > maxSize * 1000 ** 2) {
      error.value = `Cette image pèse trop lourd (${maxSize}Mo max).`

      return
    }
  }
  const reader = new FileReader()

  reader.addEventListener(
    'load',
    async () => {
      // convert image file to base64 string
      if (typeof reader.result === 'string') {
        preview.value = reader.result

        if (reader.result.includes('image/png;base64')) {
          const { data, error } = await useFetch<{
            optimized: string
            statusCode?: number
          }>(API.POST_POLISH, {
            method: 'POST',
            body: { base: reader.result },
          })

          if (error.value) {
            logError(
              'from File Preview',
              'There was a problem posting base64 image: ',
              error,
            )
          }

          const rawData = unref(data)

          if (rawData?.optimized) {
            preview.value = rawData.optimized
          }
        }
      }

      emit('change', {
        preview: preview.value,
        alt: alt.value,
      })
    },
    false,
  )

  if (file) {
    reader.readAsDataURL(file)
  }
}

const onAltChange = () => {
  emit('change', {
    preview: preview.value,
    alt: alt.value,
  })
}

const removePreview = () => {
  preview.value = ''
  alt.value = ''
  error.value = ''

  emit('change', {
    preview: preview.value,
    alt: alt.value,
  })
}

onUpdated(() => {
  if (!preview.value) {
    preview.value = props.image?.preview ?? ''
  }

  if (!alt.value) {
    alt.value = props.image?.alt ?? ''
  }
})
</script>

<style lang="scss">
.is-invalid {
  .p-input-file {
    border: 1px solid $red !important;
  }
}
</style>
<style lang="scss" scoped>
.p-input-file {
  $root: &;

  border: 1px solid #cbcbcb;

  &:hover {
    border-color: #0e76ff;

    #{$root}__waiting img {
      transform: scale(1.05);
    }
  }

  &.preview {
    border: 0;
  }

  &__input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  &__waiting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
    text-align: center;
    cursor: pointer;
    user-select: none;

    @media (min-width: 760px) {
      height: 25rem;
    }

    &.is-dragging {
      background-color: #eee;

      & > * {
        filter: brightness(0.5);
      }
    }

    & > * {
      pointer-events: none;
    }

    img {
      width: 8rem;
      height: 8rem;
      margin-bottom: 1.2rem;
      transition: transform 0.5s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    }

    p {
      span {
        opacity: 0.4;
      }
    }
  }

  &__preview {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (min-width: 760px) {
        height: 25rem;
      }
    }
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: #fff;
    cursor: pointer;
    transition: 0.3s opacity ease-in-out;

    &:hover {
      opacity: 0.9;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
